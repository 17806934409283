import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import ReactGA from "react-ga";
// import Service from "../components/Service";
// import Testimonial from "../components/Testimonial";

function About() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  // const [setServices] = useState([]);
  // const [setReviews] = useState([]);

  const clickDownloadButton = e => {
    ReactGA.event({
      category: "CV Button",
      action: "click"
    });
  };

  // const sliderSettings = {
  //   dots: false,
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 2,
  //   autoplay: true,
  //   autoplaySpeed: 6000,
  //   pauseOnHover: true,
  //   adaptiveHeight: true,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  const handleToggler = event => {
    setToggler({
      toggler: event
    });
  };

  useEffect(() => {
    axios.get("/api/information").then(response => {
      setInformation(response.data);
    });
    // axios.get("/api/services").then(response => {
    //   setServices(response.data);
    // });
    // axios.get("/api/reviews").then(response => {
    //   setReviews(response.data);
    // });
  }, []);

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top  mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <img
                  src={information.aboutImage}
                  alt="about"
                  onClick={() => handleToggler(!toggler)}
                />
                <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span>
                <FsLightbox
                  toggler={toggler}
                  sources={[information.aboutImageLg]}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <h3>
                  About <span className="color-theme">me</span>
                </h3>
                <p>
                  I am a (front-end focused) full stack developer with 7+ years
                  of work, delivering nice web experiences to brands and
                  agencies.
                </p>
                <p>
                  I like good UX, learning new technologies and I enjoy creating
                  usefull things for the web.
                </p>
                <p>
                  I attended Technical University of Cluj-Napoca (RO) to pursue
                  a degree in Computer Science. When I learned the fundamentals
                  of programming, I started teaching myself HTML, CSS and PHP
                  and worked on small projects as a freelancer developer. <br />
                  After graduation I focused more on web development, had my
                  first job as developer at an{" "}
                  <a
                    href="https://www.bss-brand.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ad agency
                  </a>{" "}
                  in Stuttgart (DE).{" "}
                </p>
                <p>
                  With a fair amount of successful projects, I started my
                  freelance carrier (working for more ad agencies) to extend my
                  skills and dig deeper in front-end development. <br />
                  Currently I'm passionate about frameworks/libraries like
                  React, Vue.js, realtime web apps, Node.js and AI for web.
                </p>
                <p>
                  I'm looking for a job at a product company to be able to focus
                  on one product, develop/deepen my skills and learn to use new
                  tools.
                </p>
                <br />
                <p>
                  I'm also a husband, a father of two (Noel and Lea - in the pic
                  on the left). I enjoy travelling, gardening and having fun
                  with my family and friends.
                </p>
                <ul>
                  {!information.name ? null : (
                    <li>
                      <b>Full Name</b> {information.name}
                    </li>
                  )}
                  {!information.age ? null : (
                    <li>
                      <b>Age</b> {information.age} years
                    </li>
                  )}
                  {!information.phone ? null : (
                    <li>
                      <b>Phone</b> {information.phone}
                    </li>
                  )}
                  {!information.nationality ? null : (
                    <li>
                      <b>Nationality</b> {information.nationality}
                    </li>
                  )}
                  {!information.language ? null : (
                    <li>
                      <b>Languages</b> {information.language}
                    </li>
                  )}
                  {!information.email ? null : (
                    <li>
                      <b>Email</b> {information.email}
                    </li>
                  )}
                  {!information.address ? null : (
                    <li>
                      <b>Address</b> {information.address}
                    </li>
                  )}
                  {!information.freelanceStatus ? null : (
                    <li>
                      <b>Freelance</b> {information.freelanceStatus}
                    </li>
                  )}
                </ul>
                <NavLink to="/resume" className="mi-button add-margin-right">
                  Skills/resume
                </NavLink>
                {/* <a
                  href={information.cvfile}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={clickDownloadButton}
                  className="mi-button"
                >
                  Download CV
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mi-service-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Services" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {services.map(service => (
                <div
                  className="col-lg-4 col-md-6 col-12 mt-30"
                  key={service.title}
                >
                  <Service content={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Reviews" />
          <div className="row justify-content-center">
            <div className="col-12">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {reviews.map(review => (
                  <Testimonial key={review.id} content={review} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  );
}

export default About;
