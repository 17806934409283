import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.scss";

import GAListener from "./components/GAListener";

import Home from "./pages/Home";
import About from "./pages/About";
import Resumes from "./pages/Resumes";
// import Portfolios from "./pages/Portfolios";
import Contact from "./pages/Contact";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <BrowserRouter>
      <GAListener trackingId="UA-108901247-2">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/resume" component={Resumes} />
          {/* <Route path="/portfolios" component={Portfolios} /> */}
          <Route path="/contact" component={Contact} />
          <Route path="/imprint" component={Imprint} />
          <Route path="/privacy-policy" component={Privacy} />
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}

export default App;
