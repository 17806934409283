import { useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router";
const sendPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  console.debug("GA|Pageview Sent: ", location.pathname);
};
const GAListener = ({ children, trackingId }) => {
  const history = useHistory();
  useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
      sendPageView(history.location, "REPLACE");
      return history.listen(sendPageView);
    }
  }, [history, trackingId]);
  return children;
};
export default GAListener;
