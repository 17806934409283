import React from "react";
import Particles from "react-particles-js";
import Layout from "../components/Layout";

function Imprint() {
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false
        }
      },
      color: {
        value: "#ffffff"
      },
      opacity: {
        value: 0.1
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out"
      }
    }
  };

  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles className="mi-home-particle" params={paramConfig} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>Legal Disclosure</h1>
                <p>Information in accordance with Section 5 TMG</p>
                <p>
                  Zoltan Seer <br /> Cart. Florilor 49/E/6
                  <br /> 535500 Gheorgheni
                </p>
                <h2>Contact Information</h2>
                <p>
                  Telephone: +40742735783
                  <br />
                  E-Mail: <a href="mailto:hello@zseer.eu">hello@zseer.eu</a>
                  <br />
                  Internet address:{" "}
                  <a href="https://zseer.eu">https://zseer.eu</a>
                </p>
                <br />
                <br />
                <h2>Disclaimer</h2>
                <strong>Accountability for content</strong>
                <br />
                The contents of our pages have been created with the utmost
                care. However, we cannot guarantee the contents' accuracy,
                completeness or topicality. According to statutory provisions,
                we are furthermore responsible for our own content on these web
                pages. In this matter, please note that we are not obliged to
                monitor the transmitted or saved information of third parties,
                or investigate circumstances pointing to illegal activity. Our
                obligations to remove or block the use of information under
                generally applicable laws remain unaffected by this as per §§ 8
                to 10 of the Telemedia Act (TMG).
                <br />
                <br />
                <strong>Accountability for links</strong>
                <br />
                Responsibility for the content of external links (to web pages
                of third parties) lies solely with the operators of the linked
                pages. No violations were evident to us at the time of linking.
                Should any legal infringement become known to us, we will remove
                the respective link immediately.
                <br />
                <br />
                <strong>Copyright</strong>
                <br /> Our web pages and their contents are subject to German
                copyright law. Unless expressly permitted by law, every form of
                utilizing, reproducing or processing works subject to copyright
                protection on our web pages requires the prior consent of the
                respective owner of the rights. Individual reproductions of a
                work are only allowed for private use. The materials from these
                pages are copyrighted and any unauthorized use may violate
                copyright laws.
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Imprint;
